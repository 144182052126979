@import url('https://fonts.googleapis.com/css?family=Roboto');

.loader {
    animation: spin 2s linear infinite;
    border: 16px solid #AEAEAE; /* Light grey */
    border-radius: 50%;
    border-top: 16px solid #337ab7; /* Blue */
    height: 120px;
    left: calc(50% - 60px);
    position: absolute;
    top: calc(50% - 60px);
    width: 120px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }

    100% { transform: rotate(360deg); }
}