.glyphicons-halflings-167-link {
    width: 20px;
    height: 20px;
    background: url(glyphicons-halflings-2-1/glyphicons-halflings-167-link.svg) center center no-repeat;
}

.glyphicons-halflings-168-link-remove {
    width: 20px;
    height: 20px;
    background: url(glyphicons-halflings-2-1/glyphicons-halflings-168-link-remove.svg) center center no-repeat;
}

.glyphicons-halflings-167-link-nextPimBlue {
    width: 20px;
    height: 20px;
    background: url(glyphicons-halflings-2-1/glyphicons-halflings-167-link-nextPimBlue.svg) center center no-repeat;
}

.glyphicons-halflings-168-link-remove-nextPimBlue {
    width: 20px;
    height: 20px;
    background: url(glyphicons-halflings-2-1/glyphicons-halflings-168-link-remove-nextPimBlue.svg) center center no-repeat;
}